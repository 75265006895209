.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 0;
}

.housingNo {
  grid-area: 1 / 1 / 2 / 2;
}
.villageNo {
  grid-area: 1 / 2 / 2 / 3;
}
.building {
  grid-area: 2 / 1 / 3 / 3;
}
.lane {
  grid-area: 3 / 1 / 4 / 2;
}
.road {
  grid-area: 3 / 2 / 4 / 3;
}
.subDistrict {
  grid-area: 4 / 1 / 5 / 2;
}
.district {
  grid-area: 4 / 2 / 5 / 3;
}
.province {
  grid-area: 5 / 1 / 6 / 2;
}
.postcode {
  grid-area: 5 / 2 / 6 / 3;
}
