.table {
  width: 100%;
  max-width: 928px;
  display: grid;
  grid-template-columns: 9.7% 38.6% 17.2% 17.2% auto;
  margin: 0 auto;
}

.header {
  height: 44px;
  background: var(--agt-tqm-pantone-1);
  color: var(--agt-white-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.title {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-right: 1px solid var(--agt-white-color);
}

.body {
  min-height: 60px;
}

.body:nth-child(even) {
  background: var(--agt-background-color-1);
}
.body:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.positionName,
.positionLevel,
.reserveForLevel,
.commissionPercent,
.managementPercent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-right: 1px solid var(--agt-disable-color-2);
}

.reserveForLevel {
  border-right: unset;
}

.positionName .input {
  width: 100%;
  max-width: 342px;
  margin-bottom: 0;
}

.reserveForLevel .selector {
  width: 100%;
  max-width: 144px;
  margin-bottom: 0;
}

.commissionPercent .input,
.managementPercent .input {
  margin-bottom: 0;
  max-width: 110px;
  margin-right: 13px;
}
