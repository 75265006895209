.container {
  padding: 0;
}
.header {
  padding: 2rem;
}
.breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  width: 129px;
}
.groupBtn {
  display: flex;
  column-gap: 24px;
}
.groupBtn .selectBtn {
  width: 160px;
}
.table {
  padding: 0 2rem;
  margin-bottom: 2rem;
}
