.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  padding: 40px;
  border-radius: 8px;
  background-color: var(--agt-white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
}
.password {
  position: relative;
}
.forgetPass {
  position: absolute;
  top: 2px;
  right: 0;
  color: var(--agt-secondary-color-1);
  cursor: pointer;
}
.forgetPass:hover {
  opacity: 0.7;
}

.logo {
  width: 200px;
  margin: 0 auto 40px;
}
.btn {
  margin: 20px auto 0;
}
