.container {
  width: 100%;
  margin: 0 auto;
}
.header {
  padding: 2rem 2rem 0;
}
.header h4 {
  margin-bottom: 14px;
}
.mainContainer {
  padding: 0;
  padding-bottom: 2rem;
  max-width: unset;
}
