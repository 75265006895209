.input {
  padding: 0 32px 0 16px !important;
  width: 100%;
}
.inputContainer {
  position: relative;
  width: 100%;
}
.calendar {
  position: absolute;
  top: 50%;
  right: 16px;
  transform-origin: center;
  transform: translateY(-50%);
  color: var(--agt-secondary-color-1);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
  color: var(--agt-primary-color-1);
}
.arrow {
  cursor: pointer;
}
.arrow svg path {
  stroke: var(--agt-primary-color);
}
.selector {
  display: flex;
}
.selector > div {
  margin: 0 5px;
}
