@font-face {
  font-family: 'NotoSans';
  font-weight: normal;
  src: url('../fonts/NotoSans/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans';
  font-weight: 300;
  src: url('../fonts/NotoSans/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans';
  font-weight: 700;
  src: url('../fonts/NotoSans//NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: normal;
  src: url('../fonts/NotoSans/NotoSansThai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: 300;
  src: url('../fonts/NotoSans/NotoSansThai-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansThai';
  font-weight: 700;
  src: url('../fonts/NotoSans//NotoSansThai-Bold.ttf') format('truetype');
}
