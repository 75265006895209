.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  padding: 40px;
  border-radius: 8px;
  background-color: var(--agt-white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
}
.form h4 {
  text-align: center;
  margin-bottom: 2rem;
}
.form .suggestion {
  position: absolute;
  top: 0px;
  left: calc(420px + 24px);
  border-radius: 8px;
  background-color: var(--agt-white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 16px 16px;
  /* width: fit-content; */
  width: 100%;
  max-width: 260px;
  display: flex;
  flex-direction: column;
}
.form .suggestion .head {
  margin-bottom: 8px;
}
.form .suggestion .row {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.form .suggestion .row small {
  margin-left: 8px;
}

.form .success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.form .success label {
  color: var(--agt-primary-color);
}
.input {
  margin-bottom: 16px;
}
.input label {
  text-align: left;
}
.form .btn {
  width: 100%;
  margin-top: 1rem;
}

@media (max-width: 1024px) {
  .form .suggestion {
    display: none;
  }
}
