.breadcrumb {
  display: flex;
  justify-content: space-between;
}
.breadcrumb .groupBtn .editBtn {
  visibility: hidden;
}
.breadcrumb .groupBtn .hidden {
  display: none;
}
.container {
  width: 100%;
  max-width: 928px;
  margin: 0 auto 2rem;
}
.container .orders .title,
.container .sales .title,
.container .commission .title {
  background-color: var(--agt-primary-color);
  padding: 8px;
  color: var(--agt-white-color);
}
.container .title.first {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.container .content {
  border: 1px solid var(--agt-background-color-2);
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.container .content .displayValue {
  display: grid;
  grid-template-columns: 25% auto;
}
