.documents .archiveButton {
  display: flex;
  justify-content: flex-end;
  padding: 0 32px;
}
.documents .archiveButton button {
  width: 200px;
  color: var(--agt-primary-color);
  border: 1px solid var(--agt-primary-color);
  background-color: var(--agt-white-color);
}
.documents .agentDocuments {
  padding-top: 24px;
}
.documents .agentDocuments .title,
.documents .agentImportant .title,
.documents .agentLicense .title {
  padding-left: 16px;
}
.documents .agentImportant .header,
.documents .agentDocuments .header {
  display: grid;
  grid-template-columns: 30% 20% 18% auto;
  padding-bottom: 4px;
  color: var(--agt-secondary-color-1);
}
.documents .agentImportant .row,
.documents .agentDocuments .row {
  position: relative;
  display: grid;
  grid-template-columns: 30% 20% 18% auto;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  align-items: center;
}
.documents .row .name {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px;
}
.documents .row .name label {
  margin-left: 10px;
  text-decoration: underline;
  color: var(--agt-primary-color);
  /* cursor: pointer; */
}
.documents .row .status {
  width: 100%;
  max-width: 160px;
  margin-bottom: 0px;
}
.documents .row .description {
  width: 100%;
  max-width: 260px;
  margin-bottom: 0px;
}
.documents .addDocuments {
  margin: 24px;
  padding: 0 10px;
  width: 164px;
}
.documents .agentImportant,
.documents .agentLicense {
  padding-top: 20px;
}
.documents .addLicenses {
  margin: 24px;
  padding: 0 10px;
  width: 201px;
}
.documents .agentLicense .header {
  display: grid;
  grid-template-columns: 25% 22% 16% 23% auto;
  padding-bottom: 4px;
  color: var(--agt-secondary-color-1);
}
.documents .agentLicense .row {
  display: grid;
  grid-template-columns: 25% 22% 16% 23% auto;
  padding-top: 16px;
  padding-bottom: 24px;
  align-items: center;
}
.documents .agentLicense .licenseId {
  width: 100%;
  max-width: 194px;
  margin-bottom: 0px;
  margin-left: 10px;
}
.documents .agentLicense .licenseType {
  width: 100%;
  max-width: 216px;
  margin-bottom: 0px;
}
.documents .agentLicense .status {
  width: 100%;
  max-width: 160px;
  margin-bottom: 0px;
}
.documents .agentLicense .endDate {
  width: 100%;
  max-width: 224px;
  margin-bottom: 0px;
}
.documents .agentLicense .description {
  width: 100%;
  max-width: 624px;
  margin-bottom: 0px;
}
.documents .agentLicense .subrow {
  position: relative;
  display: grid;
  grid-template-columns: 25% auto;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.documents .agentLicense .newBin {
  position: absolute;
  right: 16px;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ---------------------------------------------------- */
.documents .agentImportant .newRow,
.documents .agentDocuments .newRow {
  width: 100%;
  display: flex;
  padding: 24px;
  padding-top: 16px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.documents .agentImportant .row .newBin,
.documents .agentDocuments .row .newBin {
  position: absolute;
  right: 16px;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.documents .agentImportant .newRow .groupDescription,
.documents .agentDocuments .newRow .groupDescription {
  width: 100%;
  max-width: 458px;
  margin-right: 24px;
  position: relative;
}
.documents .agentImportant .newRow .groupDescription .bin,
.documents .agentDocuments .newRow .groupDescription .bin {
  z-index: 1;
  cursor: pointer;
  width: 48px;
  height: 38px;
  position: absolute;
  background: var(--agt-background-color-3);
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.documents .agentImportant .newRow .groupDescription .name,
.documents .agentDocuments .newRow .groupDescription .name {
  width: 100%;
  max-width: 458px;
  margin-bottom: 0px;
}
.documents .agentImportant .newRow .description,
.documents .agentDocuments .newRow .description {
  width: 100%;
  max-width: 479px;
  margin-bottom: 0px;
}
.documents .agentLicense .newSubrow {
  display: flex;
  padding: 24px;
  padding-top: 16px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.documents .agentLicense .newSubrow .groupDescription {
  position: relative;
  width: 100%;
  max-width: 473px;
  margin-left: 24px;
}
.documents .agentLicense .newSubrow .groupDescription .bin {
  z-index: 1;
  cursor: pointer;
  width: 48px;
  height: 38px;
  position: absolute;
  background: var(--agt-background-color-3);
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.documents .agentLicense .newSubrow .groupDescription .description {
  width: 100%;
  max-width: 473px;
  margin-bottom: 0px;
}
