.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--agt-background-color-1);
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  padding: 40px;
  border-radius: 8px;
  background-color: var(--agt-white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
}
.form h4 {
  text-align: center;
  margin-bottom: 40px;
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.success label {
  color: var(--agt-primary-color);
}
.email label {
  text-align: center;
}

.btn {
  width: 100%;
  margin: 0 0 20px 0;
}
