form.filterWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 2rem 3rem;
  margin-bottom: 2rem;
  background-color: var(--agt-background-color-1);
}
.filterRows {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
.filterInputWrapper {
  display: flex;
}
.filterInputWrapper label {
  margin-right: 10px;
  align-self: center;
}
.filterInputWrapper .filterInput {
  width: 200px;
  margin: 0;
}
.filterInputWrapper .filterName {
  width: 620px;
  margin: 0;
}
.filterInputWrapper .filterUpdateBy {
  width: 352px;
  margin: 0;
}
.reset {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: underline;
  cursor: pointer;
  color: var(--agt-primary-color);
}

@media (max-width: 1024px) {
  .filterRows {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
