.editBtn {
  margin-right: 1rem;
}
.btn {
  width: 122px;
}
.removeStructure {
  display: block;
  margin: 24px auto;
}
