.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--agt-background-color-1);
}
.container .form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  padding: 40px;
  border-radius: 8px;
  background-color: var(--agt-white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
}
.container .form h4 {
  text-align: center;
  margin-bottom: 40px;
}
.container .form .title {
  color: var(--agt-primary-color);
  margin-bottom: 24px;
}
.container .form .suggestion {
  position: absolute;
  top: 0px;
  left: calc(420px + 24px);
  border-radius: 8px;
  background-color: var(--agt-white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 16px 16px;
  width: fit-content;
  display: flex;
  flex-direction: column;
}
.container .form .suggestion .head {
  margin-bottom: 8px;
}
.container .form .suggestion .row {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.container .form .suggestion .row small {
  margin-left: 8px;
}

.form .success {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
}
.container .form .success label {
  color: var(--agt-primary-color);
}
.input {
  margin-bottom: 16px;
}
.input label {
  text-align: left;
}
.container .form .btn {
  width: 100%;
}
