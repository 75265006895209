.container {
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  list-style: none;
}
.topic {
  margin-bottom: 1rem;
}
.plan {
  margin-bottom: 2.5rem;
}
.info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin-bottom: 1.5rem;
}
