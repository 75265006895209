.documents {
  border-top: 1px solid var(--agt-secondary-color-1);
  padding: 24px 0 0 0;
}
.documents .insertExplain {
  display: grid;
  grid-template-columns: 20% auto;
  align-items: center;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  background: var(--agt-background-color-4);
  margin-bottom: 20px;
}
.documents .insertExplain .title {
  display: flex;
  align-items: center;
  padding-left: 32px;
}
.documents .insertExplain .title .iconInfo svg path {
  fill: var(--agt-primary-color);
}
.documents .insertExplain .title label {
  color: var(--agt-primary-color);
  font-weight: bold;
  margin-right: 18px;
}
.documents .insertExplain .explain {
  margin-bottom: 0;
  color: var(--agt-primary-color);
}
.documents .archiveButton {
  display: flex;
  justify-content: flex-end;
  padding: 0 32px;
}
.documents .archiveButton button {
  width: 200px;
  color: var(--agt-primary-color);
  border: 1px solid var(--agt-primary-color);
  background-color: var(--agt-white-color);
}
.documents .agentDocuments {
  padding-top: 24px;
}
.documents .agentDocuments .title,
.documents .agentImportant .title,
.documents .agentLicense .title {
  padding-left: 16px;
}
.documents .agentImportant .header,
.documents .agentDocuments .header {
  display: grid;
  grid-template-columns: 30% 20% 18% auto;
  padding-bottom: 4px;
  color: var(--agt-secondary-color-1);
}
.documents .agentImportant .row,
.documents .agentDocuments .row {
  display: grid;
  grid-template-columns: 30% 20% 18% auto;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.documents .agentImportant,
.documents .agentLicense {
  padding-top: 20px;
}
.documents .agentLicense .header {
  display: grid;
  grid-template-columns: 25% 20% 16% 23% auto;
  padding-bottom: 4px;
  color: var(--agt-secondary-color-1);
}
.documents .agentLicense .row {
  display: grid;
  grid-template-columns: 25% 20% 16% 23% auto;
  padding-top: 16px;
  padding-bottom: 16px;
  /* border-bottom: 1px solid var(--agt-secondary-color-1); */
}
.documents .agentLicense .subrow {
  display: grid;
  grid-template-columns: 25% auto;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-right: 16px;
}
.documents .agentLicense .subrow .description {
  margin-right: 24px;
  color: var(--agt-secondary-color-1);
}
/* -------------------------------------------------------- */

/* -------------------------------------------------------- */

.documents .row .name {
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
}
.documents .row .name label {
  margin-left: 10px;
  text-decoration: underline;
  color: var(--agt-primary-color);
  cursor: pointer;
}
.documents .iconUpload svg path {
  fill: var(--agt-primary-color);
}
.documents .iconUploadDisabled svg path {
  fill: var(--agt-secondary-color-1);
}
