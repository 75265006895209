.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--agt-white-color);
  /* width: 352px; */
  min-width: 398px;
  border-radius: 4px;
  /* overflow: hidden; */
}
.overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--agt-background-color-3);
  height: 46px;
  padding-right: 70px;
  padding-left: 24px;
  color: var(--agt-primary-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.close {
  position: absolute;
  right: 24px;
  color: var(--agt-disable-color-2);
  font-size: 24px;
  cursor: pointer;
}
.container {
  position: relative;
  padding: 24px 24px 10px 24px;
  display: flex;
  flex-direction: column;
}
.container .rows {
  width: 336px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.container .rows .activatedSearch {
  margin-bottom: 0;
}
.container .rows label {
  margin-bottom: 4px;
}
.container .rows b {
  color: var(--agt-primary-color);
  margin-bottom: 16px;
}

.container .rows .excelType {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.container .rows .excelType li {
  list-style: none;
}
.container .rows .excelType .customLi {
  width: fit-content;
  display: flex;
  align-items: center;
  color: var(--agt-primary-color);
  cursor: pointer;
}
.container .rows .excelType .customLi .customChecked {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 2px solid var(--agt-secondary-color-2);
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container .rows .excelType .customLi .customChecked .dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--agt-white-color);
}
.container .rows .excelType .customLi input {
  display: none;
}
.container .rows .excelType .customLi input:checked ~ .customChecked {
  border: 2px solid var(--agt-primary-color);
}
.container .rows .excelType .customLi input:checked ~ .customChecked .dot {
  background-color: var(--agt-primary-color);
}
.container .affiliateOptions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.container .affiliateOptions label {
  color: var(--agt-secondary-color-1);
}
.buttonContainer {
  display: flex;
  justify-content: center;
  padding: 0 0 14px 0;
}
.buttonContainer button {
  width: 100%;
  max-width: 160px;
  border-radius: 0;
  border-radius: 5px;
}
