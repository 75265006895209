.container {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.icon_link {
  display: flex;
  justify-content: center;
}

.icon_link svg path {
  fill: var(--agt-primary-color);
}
