form.filterWrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 50px;
  row-gap: 20px;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: var(--agt-background-color-1);
}
.filterInputWrapper {
  display: flex;
  justify-content: flex-end;
}
.filterInputSecond {
  display: flex;
  justify-content: flex-start;
}
.filterInputWrapper label {
  margin-right: 10px;
  align-self: center;
}
.filterInputWrapper .filterInput {
  width: 300px;
  margin: 0;
}
.reset {
  text-decoration: underline;
  align-self: center;
  cursor: pointer;
  color: var(--agt-primary-color);
  padding: 0 80px;
}
