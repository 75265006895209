.sidebar {
  position: relative;
  width: 0;
  transform: translateX(-64px);
  z-index: 1;
}
.container {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 224px;
  height: 100%;
  /* overflow-y: auto;
  overflow-x: hidden; */
  background-color: var(--agt-background-color-3);
  transition: width 0.5s;
}
.hidden {
  width: 64px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 0 1rem;
  color: var(--agt-primary-color);
  white-space: nowrap;
  border-bottom: 1px solid var(--agt-white-color);
  cursor: pointer;
}
.menuContainer {
  list-style-type: none;
}
.menu {
  padding: 0 0.5rem;
  height: 50px;
  /* line-height: 50px; */
  cursor: pointer;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  color: var(--agt-primary-color);
  border-bottom: 1px solid var(--agt-white-color);
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}
.menu label {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.menu .labelHover {
  display: none;
}
.menu .labelHover:after {
  display: none;
}
.menu:hover .labelHover {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(20%, -50%);
  background: var(--agt-background-color-1);
  color: var(--agt-primary-color);
  width: 100%;
  min-width: 120px;
  max-width: 120px;
  padding: 4px 10px;
  font-size: 12px;
  text-align: left;
  border-radius: 2px;
}
.menu:hover .labelHover:after {
  content: "";
  display: block;
  position: absolute;
  background: var(--agt-background-color-1);
  width: 12px;
  height: 12px;
  border-radius: 0px 0px 0px 2px;
  left: -6px;
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}
.activatedMenu {
  color: var(--agt-white-color);
  background-color: var(--agt-primary-color);
}
.activatedMenu svg path {
  fill: var(--agt-white-color);
}
.activatedHeaderMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--agt-white-color);
  background-color: var(--agt-primary-color);
}
.activatedHeaderMenu svg path {
  fill: var(--agt-white-color);
}
.disabledMenu {
  color: var(--agt-disable-color-2);
  cursor: not-allowed;
}
.disabledMenu svg path {
  fill: var(--agt-disable-color-2);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.mask {
  position: absolute;
  z-index: 1;
  width: 1120px;
  height: 100%;
}
