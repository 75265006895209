.container {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 2rem;
  background-color: white;
  border-bottom: 1px solid var(--agt-background-color-3);
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1120px;
  margin: 0 auto;
}
.hamberger {
  cursor: pointer;
}
.logo {
  width: 200px;
  opacity: 0;
}
.avatar {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 14px;
  cursor: pointer;
}
.avatar .user {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.avatar .dropdown {
  display: none;
  position: absolute;
  /* top: 32px; */
  /* left: -44%; */
  top: 36px;
  left: 0px;
  padding-top: 12px;
  width: 100%;
  min-width: 224px;
}
.avatar .dropdown .layout {
  position: relative;
  background-color: var(--agt-white-color);
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
}
.avatar .dropdown .layout .chevronTop {
  position: absolute;
  background-color: var(--agt-background-color-3);
  border-radius: 4px 0px 0px 0px;
  transform: rotate(45deg);
  width: 16px;
  height: 16px;
  right: 32px;
  top: -9px;
  border: 1px solid var(--agt-secondary-color-1);
  border-bottom: 0px;
  border-right: 0px;
}
.avatar .dropdown .layout .personal {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 14px;
  background-color: var(--agt-background-color-3);
}
.avatar .dropdown .layout .personal .potition {
  display: flex;
  justify-content: space-between;
}
.avatar .dropdown .layout .personal .potition span {
  font-weight: bold;
  color: var(--agt-primary-color);
}
.avatar .dropdown .layout .content {
  width: 100%;
  list-style-type: none;
}
.avatar .dropdown .layout .content li {
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.avatar .dropdown .layout .content li:hover {
  background-color: var(--agt-background-color-1);
}
/* .avatar:hover > .dropdown {
  display: block;
} */
.adminName {
  display: flex;
  align-items: center;
  column-gap: 14px;
}
.adminName:hover > .dropdown {
  display: block;
}
.avatar .notification {
  background: var(--agt-background-color-3);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
}
.avatar .notification > span {
  position: absolute;
  top: 35%;
  left: 70%;
  transform: translate(-50%, -50%);
  background: var(--agt-error-color);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  z-index: 1;
}
.avatar .notification .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.avatar .notification .info {
  position: absolute;
  top: 48px;
  right: -36px;
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  background: var(--agt-white-color);
  padding: 24px 0;
  width: 380px;
  z-index: 1;
  height: fit-content;
}
.avatar .notification .info > p {
  padding-left: 26px;
  margin-bottom: 10px;
  color: var(--agt-secondary-color-2);
}
.avatar .notification .info > p:last-of-type {
  padding-left: 0px;
  margin-bottom: 0px;
  text-align: center;
  color: var(--agt-secondary-color-2);
}
.avatar .notification .info > div > p {
  padding-left: 26px;
  color: var(--agt-secondary-color-2);
}
.avatar .notification .info div ul {
  list-style: none;
}
.avatar .notification .info div ul li {
  padding: 8px 14px;
  border-bottom: 1px solid var(--agt-background-color-2);
  background-color: var(--agt-background-color-1);
}
.avatar .notification .info div ul li:last-of-type {
  margin-bottom: 14px;
}
.avatar .notification .info div ul li .detail {
  display: flex;
  gap: 10px;
}
.avatar .notification .info div ul li .detail .superText {
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .avatar .notification .info div ul li p + div {
  text-align: right;
  color: var(--agt-secondary-color-2);
} */
.avatar .notification .info div ul li .time {
  text-align: right;
  color: var(--agt-secondary-color-2);
}
.avatar .notification .info div:first-of-type ul {
  /* background-color: var(--agt-background-color-1); */
}
.avatar .notification .info div ul .isRead {
  background-color: var(--agt-white-color);
}
