.container {
  list-style-type: none;
  display: flex;
  align-items: center;
  color: var(--agt-secondary-text-color);
}
.separator {
  margin: 0 5px;
  width: 7px;
}
.separator svg path {
  stroke: var(--agt-secondary-text-color);
}
