.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.label {
  margin-bottom: 8px;
}
.error {
  margin-top: 8px;
}
.toggle {
  position: absolute;
  top: 48px;
  right: 16px;
  cursor: pointer;
}
.input[data-type="password"] {
  padding: 0 48px 0 16px !important;
}
