section.container {
  overflow-x: auto;
  margin-bottom: 10px;
}

table.table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-radius: 10px;
  border: 1px solid var(--agt-background-color-3);
}
table.table thead tr {
  height: 44px;
}
table.table tbody tr {
  height: 60px;
}
table.table thead th {
  background-color: var(--agt-primary-color);
  color: var(--agt-white-color);
  text-align: left;
}
table.table thead th,
table.table tbody td {
  margin: 0;
  padding: 0.5rem;
  border-right: 1px solid var(--agt-disable-color-2);
  font-weight: normal;
}
table.table thead th:last-child,
table.table tbody td:last-child {
  border-right: 0;
}
table.table tbody tr:nth-child(even) {
  background-color: var(--agt-background-color-1);
}
table.table thead th:first-child {
  border-top-left-radius: 8px;
}
table.table thead th:last-child {
  border-top-right-radius: 8px;
}
table.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
table.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

section.pagination {
  display: flex;
  justify-content: flex-end;
}
section.pagination span.input_wrapper {
  margin-right: 7px;
}
section.pagination button.btn_pagination {
  margin-left: 3px;
}
section.pagination button {
  width: initial;
}
section.pagination input[type="number"] {
  height: 48px;
  width: 42px;
  margin: 0 10px;
}
section.pagination input::-webkit-outer-spin-button,
section.pagination input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.countArray {
  color: var(--agt-secondary-color-1);
  text-align: right;
  margin-bottom: 24px;
}
.checkBoxColumn {
  position: relative;
  display: flex;
  justify-content: center;
}
.customInput {
  width: 24px;
  height: 24px;
  background-color: var(--agt-white-color);
  border: 1px solid var(--agt-secondary-color-2);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customInput .checkIcon {
  width: 14px;
  height: 14px;
  color: var(--agt-white-color);
}
.checkBoxColumn input {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkBoxColumn input:checked ~ .customInput {
  background-color: var(--agt-primary-color);
  border: 2px solid var(--agt-secondary-color-2);
}
.checkBoxColumn input:checked ~ .customInput .checkIcon {
  color: var(--agt-white-color);
}
