.container {
  width: 100%;
  padding: 2rem 0;
  background-color: var(--agt-white-color);
}

.container h4 {
  padding-left: 2rem;
  margin-bottom: 0.8rem;
  color: var(--agt-primary-color);
}

.container > div > p {
  padding-left: 2rem;
  margin-bottom: 0.8rem;
  color: var(--agt-secondary-color-2);
}

.container > div ul {
  list-style: none;
}

.container > div:first-of-type ul {
  /* background-color: var(--agt-background-color-1); */
}

.container > div ul li {
  border-bottom: 1px solid var(--agt-background-color-2);
  padding: 24px 40px 24px 28px;
  background-color: var(--agt-background-color-1);
}

.container > div ul .isRead {
  background-color: var(--agt-white-color);
}

.container > div ul li:last-of-type {
  /* border-bottom: 0px; */
  margin-bottom: 2rem;
}

.container > div ul li .detail {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}

.container > div ul li .detail > div {
  display: flex;
  gap: 0.8rem;
}

.container > div ul li .detail > div > p {
  width: 736px;
}

.container > div ul li .detail > div + p {
  color: var(--agt-secondary-color-2);
}
/* .container > * {
  margin-bottom: 1rem;
} */
