.date {
  margin: 0;
}
.actionBtn {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
