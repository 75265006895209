.container {
}
.fillContainer {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 32px;
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
}
.memberContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 728px;
  margin: 1rem auto 2rem;
}
.addMemberBtn {
  width: 164px;
  margin-right: 1rem;
}
.member {
  color: var(--agt-primary-color);
}
