.btn {
  width: 129px;
}

.table {
  max-width: 938px;
  margin: 0 auto;
  margin-bottom: 40px !important;
}

.important {
  padding: 0 100px;
}

.important .row {
  display: grid;
  grid-template-columns: 47.8% 47.8%;
  justify-content: space-between;
  margin-bottom: 24px;
}

.important .row .col {
  max-width: 352px;
}

.important .row .col p {
  margin: 6px 0;
}

.important .row .colFull {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.important .row .colFull b {
  margin-bottom: 14px;
}

.subDetail {
  padding-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 24px;
  margin-bottom: 24px;
}

.subDetail .col {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 352px;
}

.subDetail .col label {
  margin-bottom: 14px;
}

.note {
  text-align: center;
}
