.container {
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
}
.fillContainer {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1.5rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fillContainer .name {
  width: 100%;
  max-width: 352px;
}
.fillContainer .status,
.fillContainer .author,
.fillContainer .status,
.fillContainer .avatar,
.fillContainer .banner {
  width: 100%;
  max-width: 352px;
}
.fillContainer .avatar button,
.fillContainer .banner button {
  max-width: 136px;
}
.fillContainer .avatar .infoBtn,
.fillContainer .banner .infoBtn {
  max-width: 200px;
}
.checkboxesContainer {
  position: relative;
  margin-bottom: 1rem;
  list-style-type: none;
}
.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 48px;
  margin-right: 0.5rem;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}
.checkboxInput {
  position: relative;
  width: 100%;
}
.checkboxText {
  margin-bottom: 0;
}
.checkboxSelector {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
.deleteBtn {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 38px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--agt-background-color-3);
}
.addCheckboxBtn {
  position: absolute;
  bottom: -48px;
  width: fit-content;
}
.addCheckboxBtn[data-state="expanded"] {
  bottom: -64px;
}
.refDetail {
  display: flex;
  justify-content: space-between;
}
.refDetail .detail {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.refDetail .closeRef {
  position: absolute;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--agt-background-color-3);
  width: 48px;
  height: 38px;
  border-radius: 4px;
  cursor: pointer;
}
.refDetail .closeRef .icon svg path {
  fill: var(--agt-primary-text-color);
}
.refDetail .detail label {
  padding-right: 14px;
}
.refDetail .detail .company {
  width: 480px;
  margin-bottom: 0;
}
.refDetail .addRefBtn {
  padding: 0 16px;
}
.refDetail .previewBtn {
  width: 140px;
}
