.bin {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid var(--agt-primary-color);
  border-radius: 5px;
}
.bin:hover {
  border: 1px solid var(--agt-dark-sky-color);
  cursor: pointer;
}
.bin:hover svg path {
  fill: var(--agt-dark-sky-color);
}
.container {
  max-height: 226px;
  overflow-y: auto;
}
