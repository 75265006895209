form.filterWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: var(--agt-background-color-1);
}
.filterRows {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  row-gap: 20px;
  flex-wrap: wrap;
}
.filterInputWrapper {
  display: flex;
}
.filterInputWrapper label {
  margin-right: 8px;
  align-self: center;
}
.filterInputWrapper .filterInput {
  width: 200px;
  margin: 0;
}

.filterInputWrapper .filterAgentName,
.filterInputWrapper .filterTeamName {
  width: 220px;
  margin: 0;
}
.filterInputWrapper .filterOpportunityKey,
.filterInputWrapper .filterOrderNo,
.filterInputWrapper .filterReceivedDate {
  width: 180px;
  margin: 0;
}
.filterInputWrapper .filterPolicyYear,
.filterInputWrapper .filterInstallmentNo {
  width: 140px;
  margin: 0;
}
.filterYearInputWrapper {
  display: flex;
  gap: 0.8rem;
}
.reset {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: underline;
  cursor: pointer;
  color: var(--agt-primary-color);
}

@media (max-width: 1024px) {
  .filterInputWrapper .filterAgentName,
  .filterInputWrapper .filterTeamName {
    width: 200px;
  }
  .filterInputWrapper .filterOpportunityKey,
  .filterInputWrapper .filterOrderNo,
  .filterInputWrapper .filterReceivedDate {
    width: 160px;
  }
  .filterInputWrapper .filterPolicyYear,
  .filterInputWrapper .filterInstallmentNo {
    width: 120px;
  }
}
