.topic {
  margin-bottom: 1.5rem;
}
.permission {
  display: grid;
  grid-template-columns: 100px auto;
  grid-auto-rows: 44px;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--agt-background-color-2);
  overflow: hidden;
}
.header {
  background-color: var(--agt-tqm-pantone-1);
  border-color: white !important;
  color: white;
}
.checkbox {
  text-align: center;
  line-height: 44px;
  border-right: 1px solid var(--agt-primary-text-color);
}
.text {
  padding: 0 1.5rem;
  line-height: 44px;
}
.isOdd {
  background-color: var(--agt-background-color-1);
}
