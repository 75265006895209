.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--agt-white-color);
  width: 352px;
  border-radius: 4px;
  overflow: hidden;
}
.overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
.container {
  position: relative;
  padding: 24px;
  text-align: center;
}
.children {
  padding: 2rem 0;
}
.close {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.close svg path {
  fill: var(--agt-disable-color-2);
}
.buttonContainer {
  display: flex;
}
.buttonContainer button {
  width: 100%;
  border-radius: 0;
}
.cancelButton {
  border-width: 1px 0 0 0 !important;
  border-color: var(--agt-secondary-color-1) !important;
  color: var(--agt-primary-text-color) !important;
}
