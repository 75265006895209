.container {
  padding: 0 2rem;
  margin-bottom: 2rem;
}
.selectPins {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
}

.selectPins .pins {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--agt-primary-color);
  cursor: pointer;
}
.selectPins .pins .iconPin svg path {
  fill: var(--agt-light-sky-color);
}

.unpins {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.unpins .iconUnpin svg path {
  fill: var(--agt-secondary-color-1);
}
