.previewBtn {
  display: block;
  margin-left: auto;
}
.previewModal {
  width: 722px;
  max-height: 80vh;
  overflow: auto;
}
.previewModal .topic {
  color: var(--agt-primary-color);
  text-align: left;
  margin-bottom: 1rem;
}
.previewModal > section {
  text-align: unset;
  max-height: 80vh;
}
.previewModal > div {
  display: none;
}
.previewModal .text {
  margin-bottom: 2rem;
  max-height: 30vh;
  overflow: auto;
}
.previewModal .btn {
  display: block;
  min-width: 256px;
  margin: 1rem auto 2rem;
}
.previewModal .footer {
  display: block;
  margin-bottom: 0.5rem;
  text-align: center;
}
.previewModal .consents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.previewModal .consentContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.previewModal .checkbox {
  background: var(--agt-primary-color);
  margin-right: 1rem;
}
.previewModal .consentText {
  color: var(--agt-primary-color);
}
.previewModal .logo {
  position: absolute;
  top: 1rem;
  left: 2rem;
  width: 200px;
}
.previewModal .children {
  padding: 1rem 2rem;
  padding-bottom: 0;
  margin-top: 1rem;
  border-top: 1px solid var(--agt-background-color-1);
}
