.container {
  width: 100%;
  padding: 0 2rem;
  background-color: var(--agt-primary-text-color);
}
.wrapper {
  width: 100%;
  height: 200px;
  max-width: 1120px;
  margin: 0 auto;
}
.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50%;
}
.copyright {
  color: white;
}
.version {
  position: absolute;
  top: 16px;
  color: white;
}
