.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--agt-secondary-color-1);
}
.checkIcon svg path {
  fill: white;
}
