.container {
  width: 100%;
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.label {
  font-weight: bold;
  margin-bottom: 6px;
}
.value {
  margin-bottom: 6px;
}
