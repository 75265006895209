.container {
  padding: 0;
}
.container header {
  margin: 32px 32px 32px 32px;
}

a.icon_link {
  display: flex;
  justify-content: center;
}

.filterContainer {
  margin-bottom: 20px;
}
.container .filterBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterContainer div.filterBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 32px;
}
.filterContainer form.filterWrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
  row-gap: 20px;

  background-color: var(--agt-background-color-1);
  padding: 20px;
}
.filterContainer .filterInputWrapper {
  display: flex;
  justify-content: flex-end;
}
.filterContainer .filterInputWrapper label {
  margin-right: 10px;
  align-self: center;
}
.filterContainer .filterInputWrapper .filterInput {
  width: 200px;
  margin: 0;
}
.filterContainer a {
  text-decoration: underline;
  align-self: center;
  margin-left: 60px;
}

.tableWrapper {
  margin: 0 32px 32px 32px;
}
.reset {
  text-decoration: underline;
  align-self: center;
  margin-left: 60px;
  cursor: pointer;
  color: var(--agt-primary-color);
}
