.breadcrumb {
  display: flex;
  justify-content: space-between;
}

.breadcrumb .groupBtn {
  display: flex;
}

.breadcrumb .groupBtn .hidden {
  display: none;
}

.breadcrumb .groupBtn button:last-of-type {
  margin-left: 24px;
}
