.btn {
  width: fit-content;
  vertical-align: top;
}
.btn > div {
  display: flex;
}
.historyLink {
  margin-right: 1.5rem;
}
.resendBtn {
  margin-right: 1.5rem;
}
.editBtn {
  width: 115px;
}
.icon {
  margin-right: 0.5rem;
}
.container {
  width: 100%;
  max-width: 736px;
  padding-top: 2rem;
  margin: 0 auto 4rem;
}
.avatar {
  display: block;
  margin: 0 auto 2rem;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.info .name {
  grid-area: 1 / 1 / 2 / 3;
}
.info .recommender {
  grid-area: 2 / 1 / 3 / 2;
}
.info .team {
  grid-area: 2 / 2 / 3 / 3;
}
.info .state {
  grid-area: 3 / 1 / 4 / 2;
}
.info .note {
  grid-area: 3 / 2 / 5 / 3;
  height: fit-content;
}
.info .note p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info .updater {
  grid-area: 4 / 1 / 5 / 2;
}
