.logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 24px;
  object-fit: cover;
}
.form {
  /* margin: 0 auto; */
}
.form .portfolio {
  width: 100%;
  max-width: 736px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
}
.form .portfolio .editbtn {
  position: absolute;
  right: -128px;
  top: -40px;
  display: flex;
}
.form .personal .row {
  display: flex;
  justify-content: space-between;
}
.form .portfolio .inputFull {
  width: 100%;
}
.form .portfolio .inputHalf {
  width: 100%;
  max-width: 352px;
}
.form .portfolio .firstName,
.form .portfolio .lastName {
  width: 100%;
  max-width: 280px;
}
.form .portfolio .prefixName {
  width: 100%;
  max-width: 114px;
}
.form .portfolio .gender,
.form .portfolio .datepicker,
.form .portfolio .insuranceCompanyName {
  width: 100%;
  max-width: 352px;
}
.form .portfolio .description {
  width: 100%;
  max-width: 352px;
  margin-bottom: 0;
}
.form .portfolio .interview {
  background-color: var(--agt-background-color-1);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 20px 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}
.form .portfolio .interview .row {
  display: flex;
  width: 100%;
  margin-bottom: 14px;
}
.form .portfolio .interview .row .left {
  width: 50%;
}
.form .portfolio .interview .row .right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 24px;
}
.form .portfolio .interview .row .right small {
  color: var(--agt-secondary-color-1);
}
.form .documents {
}
.genderContainer {
  width: 100%;
  max-width: 352px;
}
.interviewContainer {
}
.interviewContainer .interviewGroup {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
}
.interviewContainer .interviewGroup .interviewButton {
  width: 100%;
  max-width: 152px;
  border: 1px solid #a0a8bc;
  border-radius: 4px;
  background: #fff;
  display: flex;
  padding-left: 16px;
}
.interviewContainer .interviewGroup .interviewButton .interviewCircle {
  width: 16px;
  height: 16px;
  border: 2px solid #acb1bf;
  border-radius: 8px;
  position: relative;
}
.interviewContainer .interviewGroup .interviewButton .interviewChecked {
  background: var(--agt-white-color);
}
.interviewContainer .interviewGroup .interviewButton .interviewCircle .dot {
  position: absolute;
  background: var(--agt-primary-text-color);
  width: 8px;
  height: 8px;
  border-radius: 4px;
  top: 2px;
  left: 2px;
}
.newInterview {
  background-color: var(--agt-background-color-1);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.newInterview .titleInfo {
  margin-top: 22px;
  margin-bottom: 10px;
  color: var(--agt-secondary-color-1);
}
.newInterview .rowInterview {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 14px;
}
.newInterview .rowInterview .lastUpdatedBy {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--agt-secondary-color-1);
}
.newInterview .rowInterview .columnInterview {
  width: 48%;
  display: flex;
  flex-direction: column;
}
.newInterview .rowInterview .columnInterview .agentStatus {
  margin-bottom: 0;
}
.newInterview .rowInterview .columnInterview .activatedAt {
  align-self: flex-end;
  margin-bottom: 8px;
  color: var(--agt-secondary-color-1);
}
.newInterview .rowInterview .columnInterview .customInput {
  margin-bottom: 0px;
}
.newInterview .rowInterview .columnInterview .recruitError {
  margin-bottom: 0px;
}
.newInterview .rowInterview .columnInterview .recruitError input {
  border: 1px solid var(--agt-error-color);
}
.newInterview .rowInterview .columnInterview .recruitSelectError {
  margin-bottom: 0px;
}
.newInterview .rowInterview .columnInterview .recruitSelectError > span + div {
  border: 1px solid var(--agt-error-color);
}
.newInterview .rowInterview .columnInterview .recruitErrorMessage {
  margin-top: 8px;
  color: var(--agt-error-color);
}
.newInterview .rowInterview .columnInterview .textInput {
  align-self: flex-start;
  margin-bottom: 8px;
}
.newInterview .rowInterview .columnInterview span {
  color: var(--agt-error-color);
}
.newInterview .rowInterview .columnUplineInterview {
  width: 288px;
  position: relative;
}
.newInterview .rowInterview .columnUplineInterview .uploadInfo {
  position: absolute;
  top: 42px;
  right: -42px;
  width: 29px;
  height: 29px;
  cursor: pointer;
}
.newInterview
  .rowInterview
  .columnUplineInterview
  .uploadInfo:hover
  + .uploadInfoText {
  opacity: 1;
}
.newInterview .rowInterview .columnUplineInterview .uploadInfoText {
  opacity: 0;
  position: absolute;
  top: 78px;
  right: -154px;
  background-color: var(--agt-primary-color);
  color: var(--agt-white-color);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  font-size: 13px;
  padding: 14px;
  z-index: 2;
}
.newInterview .rowInterview .columnUplineInterview .uploadInfo svg {
  width: 29px;
  height: 29px;
}
.newInterview .rowInterview .columnNotice {
  width: 100%;
}
.newInterview .newRowInterview {
  flex-direction: column;
  row-gap: 14px;
}
.newInterview .newRowAutorize {
  width: 48%;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.newInterview .newRowAutorize .uploadInfo {
  width: 29px;
  height: 29px;
  position: absolute;
  left: 146px;
}
.newInterview .newRowAutorize .uploadInfo:hover {
  cursor: pointer;
}
.newInterview .newRowAutorize .uploadInfo:hover ~ .uploadInfoText {
  opacity: 1;
}
.newInterview .newRowAutorize .uploadInfoText {
  opacity: 0;
  position: absolute;
  top: 90px;
  left: 146px;
  background-color: var(--agt-primary-color);
  color: var(--agt-white-color);
  border-radius: 8px;
  width: fit-content;
  white-space: nowrap;
  font-size: 13px;
  padding: 14px;
}
.newInterview .rowAutorize {
  justify-content: unset;
  column-gap: 36px;
}
.newInterview .rowApprove {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 8px;
}
.newInterview .rowAutorize .checkboxAutorize {
  display: flex;
  align-items: center;
}
.newInterview .rowAutorize .checkboxAutorize .checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--agt-white-color);
  border: 1px solid var(--agt-secondary-color-2);
  margin-right: 14px;
}
.newInterview .rowAutorize .checkboxAutorize .checkedbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--agt-primary-color);
  border: 1px solid var(--agt-white-color);
  margin-right: 14px;
}
.newInterview .rowAutorize .checkboxAutorize .disabledCheckbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--agt-secondary-color-1);
  border: 1px solid var(--agt-secondary-color-1);
  margin-right: 14px;
}
.newInterview .rowAutorize .checkboxAutorize .checked {
  position: absolute;
  top: 3px;
  left: 3px;
}
.newInterview .rowAutorize .checkboxAutorize .unChecked {
  position: absolute;
  top: 3px;
  left: 3px;
  opacity: 0;
}
.newInterview .rowAutorize .checkboxAutorize .checkboxInput {
  position: absolute;
  opacity: 0;
}
.newInterview .rowAutorize .approvedText {
  color: var(--agt-success-color);
}
.newInterview .rowAutorize .waitingText {
  color: var(--agt-primary-color);
}
.newInterview .rowAutorize .rejectText {
  color: var(--agt-error-color);
}
.newInterview .displayAutorize {
  justify-content: space-between;
}
.newInterview .rowInterview .columnNoticeInterview {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.newInterview .rowInterview .approvedInterview {
  border-bottom: 1px solid var(--agt-secondary-color-1);
}
.newInterview .rowInterview .approvedInterview b {
  margin-bottom: 6px;
}
.newInterview .rowInterview .approvedInterview .explain {
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.newInterview .rowInterview .columnApprove {
  display: flex;
  column-gap: 36px;
}
.newInterview .rowInterview .columnRejectApprove {
  opacity: 0;
}
.modal {
  overflow: unset;
}
.modal .rejectEmail {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.modal .rejectEmail small,
.approvedEmail small {
  color: var(--agt-secondary-text-color);
}
.modal .rejectEmail .selectRejectEmail {
  text-align: left;
}
.modal .rejectEmail .selectTime {
  display: flex;
  justify-content: center;
  column-gap: 26px;
}
.modal .rejectEmail .selectTime .container {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  user-select: none;
}
.modal .rejectEmail .selectTime .container input {
  position: absolute;
  top: 4px;
  opacity: 0;
  cursor: pointer;
  height: fit-content;
}
.modal .rejectEmail .selectTime .container .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: 1px solid #7d828e;
  border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.modal .rejectEmail .selectTime .container:hover input ~ .checkmark {
  opacity: 0.8;
}
/* When the radio button is checked, add a blue background */
.modal .rejectEmail .selectTime .container input:checked ~ .checkmark {
  border: 1px solid #00529a;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.modal .rejectEmail .selectTime .container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.modal .rejectEmail .selectTime .container input:checked ~ .checkmark::after {
  display: flex;
}
/* Style the indicator (dot/circle) */
.modal .rejectEmail .selectTime .container .checkmark::after {
  /* top: 2px;
  left: 2px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00529a;
}
.modal .rejectEmail .nextRecruitmented {
  margin-bottom: 0px;
}
/* ------------------------------- */
.recruit {
  border-top: 1px solid var(--agt-secondary-color-1);
}
.recruit .explain {
  display: grid;
  grid-template-columns: 20% auto;
  align-items: center;
  padding: 24px 32px 24px 32px;
  background: var(--agt-background-color-4);
  margin: 40px 0 20px 0;
}
.recruit .explain .explainLabel {
  display: flex;
  align-items: center;
}
.recruit .explain label {
  color: var(--agt-primary-color);
  font-weight: bold;
  margin-right: 18px;
}
.recruit .explain .explainInput {
  margin-bottom: 0;
}
.recruit .explain .explainInput .iconInfo svg path {
  fill: var(--agt-primary-color);
}
.editArchive {
  border-top: unset;
  padding: 0;
}
/* ------------------------------- */
