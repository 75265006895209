form.filterWrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 20px;

  padding: 2rem;
  margin-bottom: 2rem;
  background-color: var(--agt-background-color-1);
}
.filterInputWrapper {
  display: flex;
  justify-content: flex-end;
}
.filterInputWrapper label {
  margin-right: 10px;
  align-self: center;
}
.filterInputWrapper .filterInput {
  width: 220px;
  margin: 0;
}
.reset {
  text-decoration: underline;
  align-self: center;
  cursor: pointer;
  color: var(--agt-primary-color);
}
