.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.label {
  margin-bottom: 8px;
}
.error {
  margin-top: 8px;
}
.uploadBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadBtn .infoBtn {
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--agt-background-color-1);
  padding: 0 16px 0 10px;
}
.uploadBtn .infoBtn .iconInfo svg {
  width: 15px;
}
.uploadBtn .infoBtn .iconInfo svg path {
  fill: var(--agt-secondary-color-1);
}
.uploadBtn .infoBtn small {
  color: var(--agt-secondary-color-1);
  padding-left: 10px;
}

.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 200px;
  height: 48px;
  border: 1px solid var(--agt-background-color-2);
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 16px;
}
.file .iconClose svg path {
  fill: var(--agt-primary-text-color);
}
.file .type {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 58px;
  height: 42px;
  color: var(--agt-white-color);
  background: var(--agt-background-color-2);
  border-radius: 4px;
}
.file .text {
  width: 100%;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PNG {
  border-color: #ffe782;
}
.PNG .uploadType {
  background-color: #ffe782;
}
.JPG {
  border-color: var(--agt-success-color);
}
.JPG .uploadType {
  background-color: var(--agt-success-color);
}
.PDF {
  border-color: #ffb4be;
}
.PDF .uploadType {
  background-color: #ffb4be;
}
.DOC {
  border-color: #cadcff;
}
.DOC .uploadType {
  background-color: #cadcff;
}
.XLX {
  border-color: #a2dfc8;
}
.XLX .uploadType {
  background-color: #a2dfc8;
}
.PPT {
  border-color: #ffbe85;
}
.PPT .uploadType {
  background-color: #ffbe85;
}
