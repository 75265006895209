.buttonContainer {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
}
.buttonContainer .genderButton {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--agt-text-primary-color);
  border: 1px solid var(--agt-secondary-color-1);
}
.buttonContainer .female {
  border-radius: 0px 4px 4px 0px;
}
.buttonContainer .male {
  border-radius: 4px 0px 0px 4px;
}
.buttonContainer .genderButton[data-state="selected"] {
  background: var(--agt-light-sky-color) !important;
  color: var(--agt-primary-color) !important;
  border: 1px solid var(--agt-primary-color) !important;
}
