.container {
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
}
.fillContainer {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(8, auto);
  grid-column-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.fillContainer .agentCode {
  grid-area: 1 / 1 / 2 / 6;
}
.fillContainer .status {
  grid-area: 1 / 6 / 2 / 11;
}
.fillContainer .nameTh {
  grid-area: 2 / 1 / 3 / 11;
  margin-bottom: 1.5rem;
}
.fillContainer .prefixNameTh {
  grid-area: 3 / 1 / 4 / 3;
}
.fillContainer .firstNameTh {
  grid-area: 3 / 3 / 4 / 7;
}
.fillContainer .lastNameTh {
  grid-area: 3 / 7 / 4 / 11;
}
.fillContainer .nameEn {
  grid-area: 4 / 1 / 5 / 11;
  margin-bottom: 1.5rem;
}
.fillContainer .prefixNameEn {
  grid-area: 5 / 1 / 6 / 3;
}
.fillContainer .firstNameEn {
  grid-area: 5 / 3 / 6 / 7;
}
.fillContainer .lastNameEn {
  grid-area: 5 / 7 / 6 / 11;
}
.fillContainer .tel {
  grid-area: 6 / 1 / 7 / 6;
}
.fillContainer .email {
  grid-area: 6 / 6 / 7 / 11;
}
.fillContainer .position {
  grid-area: 7 / 1 / 8 / 6;
}
.fillContainer .startedDate {
  grid-area: 7 / 6 / 8 / 11;
}
.fillContainer .areaCode {
  grid-area: 8 / 1 / 9 / 6;
}
.fillContainer .branchCode {
  grid-area: 8 / 6 / 9 / 11;
}

.notice .topic {
  margin-bottom: 1.5rem;
}
.notice .permission {
  display: grid;
  grid-template-columns: 100px auto;
  grid-auto-rows: 44px;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--agt-background-color-2);
  overflow: hidden;
}
.notice .permission .header {
  background-color: var(--agt-tqm-pantone-1);
  border-color: white !important;
  color: white;
}
/* .notice .permission .checkbox {
  text-align: center;
  line-height: 44px;
  border-right: 1px solid var(--agt-primary-text-color);
} */
.notice .permission .text {
  padding: 0 1.5rem;
  line-height: 44px;
}
.notice .permission .isOdd {
  background-color: var(--agt-background-color-1);
}
.notice .permission .checkbox {
  text-align: center;
  line-height: 44px;
  border-right: 1px solid var(--agt-primary-text-color);
}
.notice .permission .radio {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.notice .permission .radio input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 1;
}
.notice .permission .radio .checkmark {
  background: var(--agt-white-color);
  border: 1px solid var(--agt-secondary-color-2);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
}
.notice .permission .radio input:checked + .checkmark {
  border: 1px solid var(--agt-primary-color);
}
.notice .permission .radio input:checked + .checkmark::after {
  content: "";
  position: absolute;
  /* background: var(--agt-secondary-color-2); */
  background: var(--agt-primary-color);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .notice .permission .radio .circle {
  background: var(--agt-white-color);
  border: 1px solid var(--agt-secondary-color-2);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
}
.notice .permission .radio .circle::after {
  content: "";
  position: absolute;
  background: var(--agt-secondary-color-2);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
