.container {
  width: fit-content;
  height: fit-content;
}

.container > div {
  display: flex;
}

.container svg {
}

.container svg path {
}
