.container {
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
  padding-top: 2rem;
}
.avatar {
  display: block;
  margin: 0 auto 2rem;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.fillContainer {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 32px;
  margin: 0 0 2rem;
}
.fillContainer .note {
  position: relative;
}
.fillContainer .count {
  position: absolute;
  bottom: 1.5rem;
  right: 5px;
  color: var(--agt-secondary-text-color);
}
.planHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.planFillContainer {
  position: relative;
  margin-bottom: 0;
}
.planContainer {
  margin-bottom: 2rem;
}
.delete {
  position: absolute;
  top: 33px;
  right: -80px;
  width: fit-content;
}
.delete svg path {
  fill: currentColor;
}
