.historyBtn {
  width: fit-content;
}
.historyLink {
  margin-right: 1.5rem;
  vertical-align: top;
}
.historyBtn > div {
  display: flex;
}
.editBtn {
  width: 115px;
}
.icon {
  margin-right: 0.5rem;
}
