.container > * {
  margin-bottom: 1rem;
}
.logoutBtn {
  margin-right: 0.5rem;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.iconContainer .iconDocument svg {
  width: 36px;
  height: 36px;
  transform: rotate(15deg);
}

.iconContainer .iconDocument svg path {
  fill: var(--agt-primary-color);
  stroke: var(--agt-primary-text-color);
}
