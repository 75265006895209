.container {
}
.fillContainer {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 32px;
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
}
.memberContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 728px;
  margin: 0 auto 2rem;
}
.addMemberBtn {
  width: 164px;
  /* margin-right: 1rem; */
}
.member {
  color: var(--agt-primary-color);
}

.stuctureComission {
  margin: 0 auto;
  width: 100%;
  max-width: 728px;
  display: flex;
  flex-direction: column;
}

.stuctureComission .column {
  display: flex;
  flex-direction: column;
}

.stuctureComission .column .title {
  margin-bottom: 14px;
}

.stuctureComission .rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.stuctureComission .dateRow {
  align-self: flex-end;
  display: flex;
  align-items: center;
}

.stuctureComission .dateRow label {
  margin-right: 14px;
}

.stuctureComission .dateRow .date {
  width: 174px;
  margin-bottom: 0;
}

.removeStructureBtn {
  display: block;
  margin: 24px auto;
}
