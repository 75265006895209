.body {
  width: 100%;
  min-height: 100vh;
  background-color: var(--agt-background-color-1);
}
.container {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  min-height: 100vh;
}
.container.hasHeader {
  min-height: calc(100vh - 80px);
  padding-left: 64px;
}
