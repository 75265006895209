.btn {
  width: 135px;
}
.editBtn {
  width: 122px;
  margin-right: 1rem;
}
.displayValues {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
  width: 100%;
  max-width: 728px;
  margin: 0 auto 1rem;
}
.member {
  width: 100%;
  max-width: 728px;
  margin: 0 auto 2rem;
  text-align: right;
  color: var(--agt-primary-color);
}
