.topic {
  margin-bottom: 24px;
}
.content .article {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--agt-primary-color);
  margin-bottom: 24px;
}
.content .article .banner {
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.content .article .detail {
  padding-bottom: 40px;
  border-bottom: 0;
  word-break: break-all;
}
.content .article label {
  margin-bottom: 40px;
}
.content .ref {
  display: flex;
  justify-content: space-between;
}
.content .ref .company {
  padding-left: 360px;
  word-break: break-all;
}
.content .ref .company b {
  color: var(--agt-primary-color);
}
.content .ref .creater {
  display: flex;
  align-items: center;
}
.content .ref .creater .lastUpdatedBy {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.content .ref .creater .avatar {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
