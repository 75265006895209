.container {
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
}
.fillContainer {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1.5rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fillContainer .name {
  width: 100%;
  /* max-width: 540px; */
}
.fillContainer .agreementType,
.fillContainer .version,
.fillContainer .status,
.fillContainer .contentDisplay,
.fillContainer .consentBy {
  width: 100%;
  max-width: 352px;
}
.fillContainer .beginDate {
  width: 100%;
  max-width: 292px;
}
.fillContainer .info {
  position: relative;
  font-size: 16px;
  align-self: flex-end;
  margin-bottom: 24px;
  cursor: pointer;
}
.fillContainer .info .infoBeginDate {
  display: none;
}
.fillContainer .info:hover .infoBeginDate {
  display: block;
  position: absolute;
  left: 0;
  margin-top: 5px;
  background: var(--agt-primary-color);
  color: var(--agt-white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  width: 184px;
}
.fillContainer .enforcement .hiddenText {
  visibility: hidden;
  margin-bottom: 8px;
}
.fillContainer .enforceBtn {
  width: 100%;
  align-self: flex-start;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
}
.fillContainer .enforceBtn label {
  padding-left: 10px;
}
.fillContainer .enforceBtn:disabled svg path {
  fill: var(--agt-disable-color-1);
}
.checkboxesContainer {
  position: relative;
  margin-bottom: 1rem;
  list-style-type: none;
}
.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 48px;
  margin-right: 0.5rem;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}
.checkboxInput {
  position: relative;
  width: 100%;
}
.checkboxText {
  margin-bottom: 0;
}
.checkboxText input {
  padding-right: 72px !important;
}
.checkboxSelector {
  margin-left: 0.5rem;
  margin-bottom: 0;
}
.deleteBtn {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 38px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--agt-background-color-3);
}
.addCheckboxBtn {
  position: absolute;
  bottom: -48px;
  width: fit-content;
}
.addCheckboxBtn[data-state="expanded"] {
  bottom: -64px;
}
.changeBegin {
  width: 100%;
  max-width: 400px;
}
.sendEmail {
  width: 100%;
  max-width: 352px;
}
.enforceModal {
  width: 100%;
  max-width: 398px;
}
