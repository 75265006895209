.container {
  padding: 0px;
}
.container .header {
  padding: 2rem;
  padding-bottom: 0;
}
.container .header .breadcrumb {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.container .header .breadcrumb .info {
  position: absolute;
  left: 108px;
  display: flex;
}
.container .header .breadcrumb .info:hover > .icon {
  display: none;
}
.container .header .breadcrumb .info:hover > .icon .iconInfo svg path {
  fill: var(--agt-primary-color);
}
.container .header .breadcrumb .info .content {
  visibility: hidden;
  transition: unset;
}
.container .header .breadcrumb .info:hover > .content {
  visibility: unset;
  border-radius: 8px;
  background-color: var(--agt-primary-color);
  color: var(--agt-white-color);
  padding: 14px;
  transition: 1s ease;
}
.container .header .breadcrumb .groupBtn {
  display: flex;
}
.container .header .breadcrumb .groupBtn .selectBtn {
  margin-right: 24px;
}
.container .header .breadcrumb .groupBtn .btn {
  width: 129px;
}
