.breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonContainer {
  width: 380px;
  height: 48px;
  display: flex;
  flex-direction: row;
}
.buttonContainer .genderButton {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--agt-secondary-color-1);
  border: 1px solid var(--agt-secondary-color-1);
}
.buttonContainer .female {
  border-radius: 0px 4px 4px 0px;
}
.buttonContainer .male {
  border-radius: 4px 0px 0px 4px;
}
.buttonContainer .genderButton[data-state="selected"] {
  background: var(--agt-light-sky-color) !important;
  color: var(--agt-primary-color) !important;
  border: 1px solid var(--agt-primary-color) !important;
}

.container {
  list-style-type: none;
  width: 80%;
  margin: 2rem auto;
  color: var(--agt-secondary-text-color);
}
.item {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}
.active {
  position: relative;
  color: var(--agt-primary-text-color);
}
.active span {
  position: absolute;
  left: -6rem;
  font-weight: bold;
  color: var(--agt-primary-color);
}
.cell {
  padding: 0 5px;
}
.name {
  width: 30%;
}
.status {
  width: 40%;
}
.date {
  width: 15%;
}
.time {
  width: 15%;
}
.recruitContainer {
  width: 80%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.recruitContainer .recruitCard {
  display: flex;
  flex-direction: column;
  row-gap: 34px;
  background-color: var(--agt-background-color-1);
  border-radius: 8px;
  padding: 24px;
}
.recruitContainer .recruitCard .recruitDetail {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.recruitContainer .recruitCard .recruitDetail .recruitUpdate {
  display: flex;
  justify-content: space-between;
}
.recruitContainer .recruitCard .recruitDetail .recruitUpdate div:last-child {
  /* color: var(--agt-success-color); */
}
.recruitContainer
  .recruitCard
  .recruitDetail
  .recruitUpdate
  .recruitStatusSuccess {
  color: var(--agt-success-color);
}
.recruitContainer
  .recruitCard
  .recruitDetail
  .recruitUpdate
  .recruitStatusProcessing {
  color: var(--agt-primary-color);
}
.recruitContainer
  .recruitCard
  .recruitDetail
  .recruitUpdate
  .recruitStatusFailed {
  color: var(--agt-error-color);
}
.recruitContainer .recruitCard .recruitExplain {
  border-bottom: 1px solid var(--agt-secondary-text-color);
}
.recruitContainer .recruitCard .recruitExplain div {
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.recruitContainer .recruitCard .recruitComment {
  border-bottom: 1px solid var(--agt-secondary-text-color);
}
.recruitContainer .recruitCard .recruitComment div:first-child {
  margin-bottom: 6px;
  font-weight: bold;
}
.recruitContainer .recruitCard .recruitComment div:last-child {
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.additionLeader {
  display: flex;
  justify-content: space-between;
}
.additionLeader > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.additionLeader > div span {
  background: var(--agt-secondary-color-2);
  border: 1px solid var(--agt-secondary-color-2);
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.additionLeader > div span svg {
  color: var(--agt-white-color);
  font-size: 10px;
}
