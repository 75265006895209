.header {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header .icon {
  margin-left: 1rem;
}
.header .icon svg path {
  stroke: var(--agt-primary-color);
}
.disabled {
  cursor: not-allowed !important;
  color: var(--agt-secondary-text-color) !important;
}
.container {
  margin: 1rem 0;
  padding: 2rem 2rem 1rem;
  border-radius: 8px;
  border: 2px solid var(--agt-background-color-3);
}
.plan {
  position: relative;
  padding-left: 4rem;
}
.expander {
  position: absolute;
  top: 5px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: var(--agt-background-color-3);
  cursor: pointer;
}
.expander .icon svg path {
  stroke: white;
}
.myExpander {
  background-color: var(--agt-primary-color);
}
.child {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid var(--agt-background-color-3);
}
.name {
  margin-bottom: 0.5rem;
}
.level {
  margin-bottom: 1rem;
}
.isMe {
  color: var(--agt-primary-color);
  border-color: var(--agt-primary-color);
}
