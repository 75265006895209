.breadcrumb {
  display: flex;
  justify-content: space-between;
}
.breadcrumb .groupBtn {
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
}
.breadcrumb .groupBtn .deleteBtn {
  width: 122px;
}
/* .breadcrumb .groupBtn button:last-of-type {
  margin-left: 24px;
} */
.breadcrumb .groupBtn .hidden {
  display: none;
}
.container {
  width: 100%;
  max-width: 736px;
  margin: 0 auto 1rem;
}
.container .info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  grid-gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.container .article,
.container .reference {
  word-break: break-all;
  margin-bottom: 1.5rem;
}
