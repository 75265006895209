.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--agt-background-color-1);
}
.errorBar {
  position: fixed;
  z-index: 1;
  top: 32px;
  left: 0;
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--agt-error-color);
  color: var(--agt-white-color);
  transition: height 1s;
}
.active {
  height: 48px;
  transition: height 1s;
}
